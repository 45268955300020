import React from "react";
import {graphql, useStaticQuery, Link} from "gatsby";
import styled from "styled-components";
import Container from "./container";
import { FlexBox, Title2, styles } from "./styles";
import SVG from "../images/annamilcarz-logo-hero.inline.svg"
import Image from "./image"
import { transparentize } from "polished";

const overlay = transparentize(0.40, '#111E2F');

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${styles.colorPrimary};
  margin-top: 110px;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
}
`;

const PostImage = ({title}) => {
  const postimagequery = useStaticQuery(graphql`
        query postimagequery {
          allWordpressAcfPages(filter: {wordpress_id: {eq: 223}}) {
            nodes {
              id
              acf {
                zdjecie_glowne_obraz {
                  title
                }
              }
            }
          }
          allWordpressCategory {
            edges {
              node {
                name
                slug
              }
            }
          }
        }
  `);

  const media = postimagequery.allWordpressAcfPages.nodes[0].acf.zdjecie_glowne_obraz;
  const categories = postimagequery.allWordpressCategory.edges;

  return (
    <Wrapper>
      <BackgroundWrapper>
        <Image filename={title} />
      </BackgroundWrapper>
    </Wrapper>  
  )
}

export default PostImage