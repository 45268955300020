import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import { SectionA, FlexBox, Title1, Text, styles } from "../components/styles"
import PostImage from "../components/post-image"
import parse from "html-react-parser"
import Fade from "react-reveal/Fade"

const Meta = styled(FlexBox)`
  width: 100%;
  max-width: 730px;
  margin: 0 auto;

  span {
    color: ${styles.colorSecondary};
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    a {
      padding-left: 15px;
      color: inherit;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: ${styles.colorPrimary};
      }
    }
  }
`

const TitleBox = styled.div`
  width: 100%;
  max-width: 730px;
  padding: 40px 0;
  margin: 0 auto;

  .react-reveal {
    animation-delay: 500ms !important;
  }

  h1 {
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
  }
`

const ContentBox = styled.div`
  width: 100%;
  max-width: 920px;
  margin: 0 auto;

  .react-reveal {
    animation-delay: 700ms !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${styles.colorSecondary};
    clear: left;
  }

  h1,
  h2 {
    font-size: 18px;
    line-height: 22px;
    padding: 22px 0;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
    line-height: 17px;
    padding: 17px 0;
  }

  p {
    color: ${styles.colorGray};
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
  }

  a {
    color: ${styles.colorSecondary};
    text-decoration: none;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
  }

  .alignleft {
    float: left;
    margin-right: 15px;
    clear: left;
  }

  .alignright {
    float: right;
    margin-left: 15px;
    clear: right;
  }
`

const LinkBox = styled(FlexBox)`
  width: 100%;

  a {
    font-size: 12px;
    line-height: 14px;
    color: ${styles.colorPrimary};
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s ease;

    &:hover {
      color: ${styles.colorSecondary};
    }
  }
`

const NewPost = ({ pageContext }) => {
  const {
    title,
    featured_media,
    acf,
    categories,
    date,
    index,
    first,
    last,
    pageCount,
  } = pageContext
  // const previousUrl = index - 1 === 1 ? '/' : (index - 1).toString();
  // const nextUrl = (index + 1).toString();

  // if (group.featured_media) {
  //   const imageTitle = group.featured_media.title;
  // }

  const article = parse(acf.artykul)
  const formatedDate = formatDate(date)

  return (
    <Layout>
      <SEO title="Aktualności" />
      <PostImage title={featured_media ? featured_media.title : "Header"} />
      <SectionA>
        <Container>
          <Meta justify={"space-between"} p={"40px 0 0 0"}>
            <Fade>
              <span>{formatedDate}</span>
            </Fade>
            <Fade>
              <FlexBox direction={"column"} align="flex-end">
                <span>Autor: Anna Milcarz</span>
                <FlexBox>{categories.map(node => cat(node))}</FlexBox>
              </FlexBox>
            </Fade>
          </Meta>
          <TitleBox>
            <Fade>
              <Title1 color={styles.colorPrimary}>{title}</Title1>
            </Fade>
          </TitleBox>
          <ContentBox>
            <Fade>{article}</Fade>
          </ContentBox>
          <LinkBox justify={"center"} p={"20px 0 70px"}>
            <Fade>
              <Link to="blog">Powrót do aktualności</Link>
            </Fade>
          </LinkBox>
        </Container>
      </SectionA>
    </Layout>
  )
}

export default NewPost

const cat = node => {
  if (node.name !== "Bez kategorii") {
    return (
      <span>
        <Link to={`kategoria/${node.slug}`}>#{node.name}</Link>
      </span>
    )
  }
}

function formatDate(date) {
  const dateArr = date.slice(0, 10).split("-")
  const replaceMonth = month => {
    if (month === "01") {
      month = "styczeń"
    } else if (month === "02") {
      month = "luty"
    } else if (month === "03") {
      month = "marzec"
    } else if (month === "04") {
      month = "kwiecień"
    } else if (month === "05") {
      month = "maj"
    } else if (month === "06") {
      month = "czerwiec"
    } else if (month === "07") {
      month = "lipiec"
    } else if (month === "08") {
      month = "sierpień"
    } else if (month === "09") {
      month = "wrzesień"
    } else if (month === "10") {
      month = "październik"
    } else if (month === "11") {
      month = "listopad"
    } else {
      month = "grudzień"
    }
    return month
  }
  dateArr[1] = replaceMonth(dateArr[1])
  return dateArr.reverse().join(" ")
}
